import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';

const pageUrls = require('../constants')

export default function Template({ data }) {
  return (
    <Layout nav="colored-nav">
      <HelmetComponent title="Privacy Policy" description="It is Glory Reborn Inc's policy to respect your privacy regarding any information we may
            collect while operating our website." />
      <section id="press-hero">
        <div className="container mt130">
          <h1>Welcome to our Privacy Policy</h1>
          <h3>Your privacy is critically important to us.</h3>
          Glory Reborn Inc. is located at:
          <br />
          <address>
            Glory Reborn Inc.
            <br />
            <br />
            63324189337{' '}
          </address>
          <p>
            It is Glory Reborn Inc's policy to respect your privacy regarding any information we may
            collect while operating our website. This Privacy Policy applies to{' '}
            <a className="purple" href={pageUrls.HOME_URL}>
              https://gloryreborn.org
            </a>{' '}
            (hereinafter, "us", "we", or "https://gloryreborn.org"). We respect your privacy and are
            committed to protecting personally identifiable information you may provide us through
            the Website. We have adopted this privacy policy ("Privacy Policy") to explain what
            information may be collected on our Website, how we use this information, and under what
            circumstances we may disclose the information to third parties. This Privacy Policy
            applies only to information we collect through the Website and does not apply to our
            collection of information from other sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms and conditions posted on our Website, set
            forth the general rules and policies governing your use of our Website. Depending on
            your activities when visiting our Website, you may be required to agree to additional
            terms and conditions.
          </p>
          <h2>Website Visitors</h2>
          <p>
            Like most website operators, Glory Reborn Inc collects non-personally-identifying
            information of the sort that web browsers and servers typically make available, such as
            the browser type, language preference, referring site, and the date and time of each
            visitor request. Glory Reborn Inc's purpose in collecting non-personally identifying
            information is to better understand how Glory Reborn Inc's visitors use its website.
            From time to time, Glory Reborn Inc may release non-personally-identifying information
            in the aggregate, e.g., by publishing a report on trends in the usage of its website.
          </p>
          <p>
            Glory Reborn Inc also collects potentially personally-identifying information like
            Internet Protocol (IP) addresses for logged in users and for users leaving comments on
            https://gloryreborn.org blog posts. Glory Reborn Inc only discloses logged in user and
            commenter IP addresses under the same circumstances that it uses and discloses
            personally-identifying information as described below.
          </p>
          <h2>Gathering of Personally-Identifying Information</h2>
          <p>
            Certain visitors to Glory Reborn Inc's websites choose to interact with Glory Reborn Inc
            in ways that require Glory Reborn Inc to gather personally-identifying information. The
            amount and type of information that Glory Reborn Inc gathers depends on the nature of
            the interaction. For example, we ask visitors who sign up for a blog at
            https://gloryreborn.org to provide a username and email address.
          </p>
          <h2>Security</h2>
          <p>
            The security of your Personal Information is important to us, but remember that no
            method of transmission over the Internet, or method of electronic storage is 100%
            secure. While we strive to use commercially acceptable means to protect your Personal
            Information, we cannot guarantee its absolute security.
          </p>
          <h2>Advertisements</h2>
          <p>
            Ads appearing on our website may be delivered to users by advertising partners, who may
            set cookies. These cookies allow the ad server to recognize your computer each time they
            send you an online advertisement to compile information about you or others who use your
            computer. This information allows ad networks to, among other things, deliver targeted
            advertisements that they believe will be of most interest to you. This Privacy Policy
            covers the use of cookies by Glory Reborn Inc and does not cover the use of cookies by
            any advertisers.
          </p>
          <h2>Links To External Sites</h2>
          <p>
            Our Service may contain links to external sites that are not operated by us. If you
            click on a third party link, you will be directed to that third party's site. We
            strongly advise you to review the Privacy Policy and terms and conditions of every site
            you visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the content, privacy policies
            or practices of any third party sites, products or services.
          </p>
          <h2>https://gloryreborn.org uses Google AdWords for remarketing</h2>
          <p>
            Https://gloryreborn.org uses the remarketing services to advertise on third party
            websites (including Google) to previous visitors to our site. It could mean that we
            advertise to previous visitors who haven't completed a task on our site, for example
            using the contact form to make an enquiry. This could be in the form of an advertisement
            on the Google search results page, or a site in the Google Display Network. Third-party
            vendors, including Google, use cookies to serve ads based on someone's past visits. Of
            course, any data collected will be used in accordance with our own privacy policy and
            Google's privacy policy.
          </p>
          <p>
            You can set preferences for how Google advertises to you using the Google Ad Preferences
            page, and if you want to you can opt out of interest-based advertising entirely by
            cookie settings or permanently using a browser plugin.
          </p>
          <h2>Aggregated Statistics</h2>
          <p>
            Glory Reborn Inc may collect statistics about the behavior of visitors to its website.
            Glory Reborn Inc may display this information publicly or provide it to others. However,
            Glory Reborn Inc does not disclose your personally-identifying information.
          </p>
          <div id="cookies">
            <h2>Cookies</h2>
            <p>
              To enrich and perfect your online experience, Glory Reborn Inc uses "Cookies", similar
              technologies and services provided by others to display personalized content,
              appropriate advertising and store your preferences on your computer.
            </p>
            <p>
              A cookie is a string of information that a website stores on a visitor's computer, and
              that the visitor's browser provides to the website each time the visitor returns.
              Glory Reborn In.c uses cookies to help Glory Reborn Inc identify and track visitors,
              their usage of https://gloryreborn.org, and their website access preferences. Glory
              Reborn Inc visitors who do not wish to have cookies placed on their computers should
              set their browsers to refuse cookies before using Glory Reborn Inc's websites, with
              the drawback that certain features of Glory Reborn Inc's websites may not function
              properly without the aid of cookies.
            </p>
            <p>
              By continuing to navigate our website without changing your cookie settings, you
              hereby acknowledge and agree to Glory Reborn Inc's use of cookies.
            </p>
          </div>
          <h2>E-commerce</h2>
          <p>
            Those who engage in transactions with Glory Reborn Inc – by purchasing Glory Reborn
            In.c's services or products, are asked to provide additional information, including as
            necessary the personal and financial information required to process those transactions.
            In each case, Glory Reborn Inc collects such information only insofar as is necessary or
            appropriate to fulfill the purpose of the visitor's interaction with Glory Reborn Inc.
            Glory Reborn Inc does not disclose personally-identifying information other than as
            described below. And visitors can always refuse to supply personally-identifying
            information, with the caveat that it may prevent them from engaging in certain
            website-related activities.
          </p>
          <h2>Privacy Policy Changes</h2>
          <p>
            Although most changes are likely to be minor, Glory Reborn Inc may change its Privacy
            Policy from time to time, and in Glory Reborn Inc's sole discretion. Glory Reborn Inc
            encourages visitors to frequently check this page for any changes to its Privacy Policy.
            Your continued use of this site after any change in this Privacy Policy will constitute
            your acceptance of such change.
          </p>
          <h2></h2>
          <p></p>
          <h2>Credit & Contact Information</h2>
          <p>
            This privacy policy was created at{' '}
            <a
              className="purple"
              href={pageUrls.TERMS_AND_CONDITIONS_GENERATOR_URL}
              title="Privacy policy template generator"
              target="_blank"
            >
              termsandconditionstemplate.com
            </a>
            . If you have any questions about this Privacy Policy, please contact us via{' '}
            <a className="purple" href={pageUrls.GLORY_REBORN_MAIL_TO}>
              email
            </a>{' '}
            or{' '}
            <a className="purple" href={pageUrls.GLORY_REBORN_TEL_NUM}>
              phone
            </a>
            .
          </p>
        </div>
      </section>
    </Layout>
  );
}

export const privacyPolicyPageQuery = graphql`
  query privacyPolicyPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
